<template>
    <div class="height1">
        <!--      头部      -->
        <div class="iq-top-navbar">
            <el-page-header @back="goBack" content="添加复诊">
            </el-page-header>
        </div>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <el-scrollbar v-show="showInput" class="left-nav-wrap" style="height:100%;">
                    <el-descriptions class="margin-top" :column="3" border>
                        <el-descriptions-item label="患者姓名">{{patientDetail.name}}</el-descriptions-item>
                        <el-descriptions-item label="年龄">{{patientDetail.age}}岁</el-descriptions-item>
                        <el-descriptions-item label="手机号">{{patientDetail.phone}}</el-descriptions-item>
                        <el-descriptions-item label="身份证号">{{patientDetail.idNo}}</el-descriptions-item>
                        <el-descriptions-item label="联系地址">{{patientDetail.address}}</el-descriptions-item>
                    </el-descriptions>
                    <el-divider></el-divider>
                    <el-form :model="diagnoseForm" ref="diagnoseForm" :rules="rules" label-width="100px" style="width: 1100px">
                        <el-form-item label="主诉" prop="main">
                            <el-input v-model="diagnoseForm.main"></el-input>
                        </el-form-item>
                        <el-form-item label="现病史">
                            <el-input type="textarea" v-model="diagnoseForm.current"></el-input>
                        </el-form-item>
                        <el-form-item label="既往史">
                            <el-input type="textarea" v-model="diagnoseForm.past"></el-input>
                        </el-form-item>
                        <el-form-item label="过敏史">
                            <el-input v-model="diagnoseForm.Irritability"></el-input>
                        </el-form-item>
                        <el-form-item label="家族史">
                            <el-input v-model="diagnoseForm.family"></el-input>
                        </el-form-item>
                        <el-form-item label="疾病诊断" prop="diseaseDiagnose">
                            <el-input v-model="diagnoseForm.diseaseDiagnose"></el-input>
                        </el-form-item>
                        <el-form-item label="体格检查">
                            <el-descriptions class="margin-top" :column="4" border>
                                <el-descriptions-item label="体温"><el-input class="small-input" v-model="diagnoseForm.temperature" size="small"></el-input>℃</el-descriptions-item>
                                <el-descriptions-item label="身高"><el-input class="small-input" v-model="diagnoseForm.height" size="small"></el-input>CM</el-descriptions-item>
                                <el-descriptions-item label="体重"><el-input class="small-input" v-model="diagnoseForm.weight" size="small"></el-input>KG</el-descriptions-item>
                                <el-descriptions-item label="BMI"><el-input class="small-input" v-model="diagnoseForm.BMI" size="small"></el-input></el-descriptions-item>
                                <el-descriptions-item label="心率"><el-input class="small-input" v-model="diagnoseForm.heartRate" size="small"></el-input></el-descriptions-item>
                                <el-descriptions-item label="血压"><el-input class="small-input" v-model="diagnoseForm.heightTension" size="small"></el-input>/<el-input v-model="diagnoseForm.lowTension" class="small-input" size="small"></el-input>mmHg</el-descriptions-item>
                                <el-descriptions-item label="呼吸"><el-input class="small-input" v-model="diagnoseForm.breathe" size="small"></el-input></el-descriptions-item>
                                <el-descriptions-item label="脉搏"><el-input class="small-input" v-model="diagnoseForm.Pulse" size="small"></el-input></el-descriptions-item>

                            </el-descriptions>
                        </el-form-item>
                        <el-form-item label="症状照片">
                            <el-upload
                                    :headers="headers"
                                    :action="FileUploadUrl"
                                    list-type="picture-card"
                                    :limit="5"
                                    multiple
                                    :on-preview="DiagnoseImagePreview"
                                    :on-remove="DiagnoseImageRemove"
                                    :on-success="DiagnoseImageUpload"
                                    :file-list="ImageContentList">
                                <i class="el-icon-plus"></i>
                                <div class="el-upload__tip" slot="tip">最多上传5张</div>
                            </el-upload>
                            <el-dialog :visible.sync="Image.dialogVisible">
                                <img width="100%" :src="Image.dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                        <el-form-item label="上传处方">
                            <el-upload
                                    :headers="headers"
                                    :action="FileUploadUrl"
                                    list-type="picture-card"
                                    :limit="5"
                                    multiple
                                    :on-preview="handleImagePreview"
                                    :on-remove="handleImageRemove"
                                    :on-success="handleImageUpload"
                                    :file-list="ImageContentList">
                                <i class="el-icon-plus"></i>
                                <div class="el-upload__tip" slot="tip">最多上传5张</div>
                            </el-upload>
                            <el-dialog :visible.sync="Image.dialogVisible">
                                <img width="100%" :src="Image.dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="onSubmit('diagnoseForm')">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-scrollbar>
                <div v-show="showResult">
                    <el-result icon="success" title="复诊添加成功!">
                        <template slot="extra">
                            <el-button type="primary" size="medium" @click="goBack()">返回</el-button>
                        </template>
                    </el-result>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {Patient} from "../../../components/HospitalDomain/Patient/Patient";

    export default {
        name: "AddDiagnostic",
        computed: {
            headers() {
                return {
                    "Authorization": 'Bearer' + ' ' + JSON.parse(sessionStorage.getItem(this.ClientId + 'Token')).access_token
                }
            }
        },
        data(){
            var patient = new Patient(this.TokenClient, this.Services.Authorization)
            return{
                patientDomain: patient,
                id: this.$route.query.id, //列表页id
                FileUploadUrl: this.Services.Authorization+'/api/File/upload',
                dialogImageUrl: '',
                dialogVisible: false,
                Image: {
                    //单击图片上传控件中的图片时，放大显示
                    dialogVisible: false,
                    dialogImageUrl: '',
                },
                ImageContent: [],//图片列表
                ImageContentList: [],//图片上传控件加载的数据
                DiagnoseContent: [],//图片列表
                patientDetail:{},
                diagnoseForm:{
                    type: 2,
                    organizationId: JSON.parse(sessionStorage.getItem('userInfo')).organizationId,
                    patientId: 0,
                    /*main: "",
                    current: "",
                    past: "",
                    Irritability: "",
                    family: "",
                    diseaseDiagnose: "",

                    BMI: "" ,
                    Pulse: "",
                    breathe: "",
                    heartRate: "",
                    height: "",
                    heightTension: "",
                    lowTension: "",
                    temperature: "" ,
                    weight:"",*/

                    DiagnoseImgList: [],
                    PrescriptionImgList: [],
                },
                showInput: true,
                showResult: false,
                rules:{
                    name:[
                        { required: true, message: '请输入患者姓名', trigger: 'blur' },
                    ],
                    age:[
                        { required: true, message: '请输入患者年龄', trigger: 'blur' },
                    ],
                    phone:[
                        { required: true, message: '请输入患者联系方式', trigger: 'blur' },
                        { pattern:/^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur"}
                    ],
                    idNo:[
                        { required: true, message: '请输入身份证号码', trigger: 'blur' },
                        { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: "请输入正确格式的身份证号", trigger: "blur"},
                    ],
                    main:[
                        { required: true, message: '请输入主诉', trigger: 'blur' },
                    ],
                    diseaseDiagnose:[
                        { required: true, message: '请输入疾病诊断', trigger: 'blur' },
                    ],
                }
            }
        },
        mounted() {
            if(this.id){
                this.getDetail()
            }
        },
        methods:{
            goBack(){
                this.$router.go(-1)
            },
            getDetail(){
                var _this = this;
                _this.patientDomain.GetDetail(_this.id,
                    function (data) {
                        _this.patientDetail = data.data,
                        _this.diagnoseForm.patientId = _this.id
                    },
                    function (err) {

                    });
            },
            handleImageUpload(response, file, fileList) {
                var _this = this;
                response.forEach(function (file) {
                    var imgarr = {
                        "filePath": file.completeFilePath,
                        "fileName": file.fileName,
                        "fileHash": file.fileHash,
                        "fileExt": file.fileExtension,
                    }

                    _this.ImageContent.push(imgarr);
                });
                _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
                console.log(_this.diagnoseForm.PrescriptionImgList)

            },
            handleImagePreview(file) {
                this.Image.dialogImageUrl = file.url;
                this.Image.dialogVisible = true;
            },
            handleImageRemove(file, fileList) {
                var _this = this;
                const filePath = file.response[0].completeFilePath;
                //2.从pics数组中找到图片对应的索引值
                //形参‘x’，是数组pic里的每一项
                const i = _this.ImageContent.findIndex(x =>
                    x.completeFilePath == filePath);
                //调用数组的splice方法，把图片的信息对象从pics数组中移除
                _this.ImageContent.splice(i,1);
                _this.diagnoseForm.PrescriptionImgList = _this.ImageContent;
            },
            DiagnoseImageUpload(response, file, fileList) {
                var _this = this;
                response.forEach(function (file) {
                    var imgarr = {
                        "filePath": file.completeFilePath,
                        "fileName": file.fileName,
                        "fileHash": file.fileHash,
                        "fileExt": file.fileExtension,
                    }

                    _this.DiagnoseContent.push(imgarr);
                });
                _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
                console.log(_this.diagnoseForm.DiagnoseImgList)

            },
            DiagnoseImagePreview(file) {
                this.Image.dialogImageUrl = file.url;
                this.Image.dialogVisible = true;
            },
            DiagnoseImageRemove(file, fileList) {
                var _this = this;
                const filePath = file.response[0].completeFilePath;
                //2.从pics数组中找到图片对应的索引值
                //形参‘x’，是数组pic里的每一项
                const i = _this.DiagnoseContent.findIndex(x =>
                    x.completeFilePath == filePath);
                //调用数组的splice方法，把图片的信息对象从pics数组中移除
                _this.DiagnoseContent.splice(i,1);
                _this.diagnoseForm.DiagnoseImgList = _this.DiagnoseContent;
            },
            onSubmit(formName){
                var _this = this;
                console.log(this.diagnoseForm)
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        _this.patientDomain.DiagnoseAdd(_this.diagnoseForm,
                            function (data) {
                                _this.showInput = false;
                                _this.showResult = true;
                            },
                            function (error) {
                                console.log(error);
                            });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
    .el-page-header{
        line-height: 73px;
        padding-left: 20px;
    }
    .small-input{
        display: inline-block;
        width: 100px;
        margin: 0 5px;
    }
</style>
